import React from 'react'
import PropTypes from 'prop-types'
import InputMask from "react-input-mask";
import Recaptcha from "react-google-recaptcha";

const ConsultationForm = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {recaptchaComplete: false, compactRecaptcha: false, phone: ""};
  }

  handleChange = e => {
    if (e.target.name === "phone") {
      if (e.target.value[0] == "1") {
        e.target.value = e.target.value.substr(1);
      } else if (e.target.value[0] == "+") {
        e.target.value = e.target.value.substr(2);
      } else if (e.target.value.substring(0,2) == "(1") {
        return;
      }
      this.setState({phone: e.target.value});
    }
  }

  handleRecaptcha = value => {
    this.setState({ recaptchaComplete: value });
  };

  componentDidMount() {
    if ( window.innerWidth <= 370) {
      this.setState({compactRecaptcha: true});
    }
  }

  render() {
    return (
      <div className={this.props.darkTheme ? "dark consultation-form" : "light consultation-form"}>
        <div className="form-header">Request a Free consultation</div>
        <form action="/?form-submitted" name="consultation" data-netlify="true" data-netlify-recaptcha="true" data-netlify-honeypot="bot-field">
            <input type="hidden" name="form-name" value="consultation" />
            <p class="hidden" style={{display:"none"}}>
              <label>Don’t fill this out if you're human: <input name="bot-field" /></label>
            </p>
            <input type="text" name="name" placeholder="Full Name" required/>
            <InputMask mask={this.state.phone ? "(999) 999-9999" : ""} name="phone" value={this.state.phone} onChange={this.handleChange} placeholder="Phone Number" required/>
            <input type="email" name="email" placeholder="Email Address" required/>
            <div style={{position: "relative"}}>
                <select name="type" required>
                    <option disabled selected value> Type of Accident </option>
                    <option value="car">Car Accident</option>
                    <option value="truck">Truck Accident</option>
                    <option value="bus">Bus Accident</option>
                    <option value="rideshare">Uber/Lyft Accident</option>
                    <option value="motorcyle">Motorcyle Accident</option>
                    <option value="bicycle">Bicycle Accident</option>
                    <option value="pedestrian">Pedestrian Accident</option>
                    <option value="scooter">Scooter Accident</option>
                </select>
                <div className="arrow-down"></div>
            </div>
            <div className="recaptcha-container">
              <Recaptcha
                ref="recaptcha"
                sitekey="6LcZlQEVAAAAAKeFZLHoEUtmWGa3knzfeOIh8r0G"
                onChange={this.handleRecaptcha}
                size={this.state.compactRecaptcha ? "compact" : "normal"}
              />
            </div>
            <button type="submit">Get a Free Consultation</button>
        </form>
        <div className="privacy-statement">Your information is kept strictly private</div>
      </div>
    )
  }
}

ConsultationForm.propTypes = {
    darkTheme: PropTypes.bool,
}

export default ConsultationForm
