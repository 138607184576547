import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Brain from '../images/icn-brain.svg'
import BrokenBones from '../images/icn-brokenbones.svg'
import Death from '../images/icn-death.svg'
import Disfigurement from '../images/icn-disfigurement.svg'
import Emotional from '../images/icn-emotional.svg'
import Impairment from '../images/icn-impairment.svg'
import Spinal from '../images/icn-spinal.svg'
import Surgeries from '../images/icn-surgeries.svg'

const ContentIcons = ({ data }) => (
  <div className="content-icons">
    <h2>{data.title}</h2>
    <div className="icons">

      <div className="item">
        <div className="icon">
          <Disfigurement/>
        </div>
        Disfigurement
      </div>

      <div className="item">
        <div className="icon">
          <Impairment/>
        </div>
        Impairment
      </div>

      <div className="item">
        <div className="icon">
          <Spinal/>
        </div>
        Spinal<br/>Injury
      </div>

      <div className="item">
        <div className="icon">
          <Surgeries/>
        </div>
        Surgeries
      </div>

      <div className="item">
        <div className="icon">
          <Emotional/>
        </div>
        Emotional<br/>Trauma
      </div>

      <div className="item">
        <div className="icon">
          <Death/>
        </div>
        Death
      </div>

      <div className="item">
        <div className="icon">
          <Brain/>
        </div>
        Brain<br/>Injury
      </div>

      <div className="item">
        <div className="icon">
          <BrokenBones/>
        </div>
        Broken<br/>Bones
      </div>

        {/* {data.iconList.map((item, index) => (
          <div className="item">
            {item.itemText}
          </div>
        ))} */}
      </div>
  </div>
)

ContentIcons.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    checklist: PropTypes.array,
  }),
}

export default ContentIcons
