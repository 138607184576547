import React from 'react'
import PropTypes from 'prop-types'
import ConsultationForm from './ConsultationForm'
import FormConfirmation from './FormConfirmation'
import { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';


const CallToAction = ({ data, forceShowPopUp, forceClosePopUp }) => {
  const [showPopUp, setShowPopUp] = useState(false);
  const [formConf, setFormConf] = useState(false);
  const [confShown, setConfShown] = useState(false);

  useEffect(() => {
    if (window.performance) {
      // if (performance.navigation.type == 1) {
        if (window.location.href.indexOf("form-submitted") > -1 && !confShown) {
          window.gtag('event', 'conversion', {'send_to': 'AW-636876801/Q2j8COzRvdABEIHw168C'});
          setShowPopUp(true)
          setFormConf(true)
        }
      // } 
  }
 })

  return (
    <div className="call-to-action">
      <h2>{data.largeTitle}</h2>
      <div className="subtitles">
        <a href="tel:4353196156">
          <strong>{data.subtitleBold}</strong>
        </a>
      </div>
      <div className="cta-btn" onClick={() => showPopUp ? setShowPopUp(false) : setShowPopUp(true)}>
        Get a Free Consultation
      </div>

      <div className="pop-up-background" style={(showPopUp || forceShowPopUp) ? {display: "flex"} : {display: "none"}}>
        <div className="pop-up-form">
          <div style={formConf ? {display: "none"} : {display: "flex"}}>
            <ConsultationForm darkTheme={false} />
          </div>
          <div  style={formConf ? {display: "flex"} : {display: "none"}}>
            <FormConfirmation onClose={() => {
              setConfShown(true);
              if (window.location.href.indexOf("?form-submitted") > -1) {
                window.location.href = window.location.href.replace("?form-submitted", "");
              }
              setShowPopUp(false);
              setFormConf(false);
              forceClosePopUp();
            }} />
          </div>
          <FaTimes onClick={() => {
            setConfShown(true);
            if (window.location.href.indexOf("?form-submitted") > -1) {
              window.location.href = window.location.href.replace("?form-submitted", "");
            }
            setShowPopUp(false);
            setFormConf(false);
            forceClosePopUp();
          }} />
        </div>
      </div>
    </div>
  )
}

CallToAction.propTypes = {
  data: PropTypes.shape({
    largeTitle: PropTypes.string,
    subtitleBold: PropTypes.string,
    subtitleLight: PropTypes.string,
  }),
  forceShowPopUp: PropTypes.bool,
  forceClosePopUp: PropTypes.func,
}

export default CallToAction
