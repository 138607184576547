import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import CallCenter from '../images/law_call-center.svg'
import Scales from '../images/law_scales.svg'
import Money from '../images/law_money.svg'

const ContentSteps = ({ data }) => (
  <div className="content-steps">
    <h5>{data.title}</h5>
    <h2>{data.subtitle}</h2>
    <div className="steps">
        {data.contentSteps.map((item, index) => (
          <div className="step">
            {index == 0 && <CallCenter/>}
            {index == 1 && <Scales/>}
            {index == 2 && <Money/>}
            <div className="text">
              <strong>{item.stepTitle}</strong>
              <p>
                {item.stepDescription}
              </p>
            </div>
          </div>
        ))}
      </div>
  </div>
)

ContentSteps.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    contentSteps: PropTypes.array,
  }),
}

export default ContentSteps
