import React from 'react'
import PropTypes from 'prop-types'
import CheckCircle from '../images/check-circle.svg'

const FormConfirmation = ({ onClose }) => {

  return (
    <div className="form-confirmation">
        <div className="form-header">Request Sent!</div>
        <CheckCircle id="check-circle" />
        <p>
            Someone from our team will be in touch<br/>with you shortly. Thank you!
        </p>
        <button onClick={() => onClose()}>Close</button>
    </div>
  )
}

FormConfirmation.propTypes = {
    onClose: PropTypes.func,
}

export default FormConfirmation
