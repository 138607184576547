import React from 'react'
import PropTypes from 'prop-types'
import Slider from './Slider'
import TestimonialSlide from './TestimonialSlide'

const TestimonialSlider = class extends React.Component {
  // constructor( props ){
  //   super( props );
  //   this.decrementCurrentSlide = this.decrementCurrentSlide.bind(this);
  //   this.incrementCurrentSlide = this.incrementCurrentSlide.bind(this);
  // }

  // state = {
  //   slideIndex: 0
  // }

  // decrementCurrentSlide() {
  //   if (this.state.slideIndex === 0 ) {
  //     this.setState({ slideIndex: this.props.slider.length - 1 })
  //   } else {
  //     this.setState({ slideIndex: this.state.slideIndex - 1 })
  //   }
  // }

  // incrementCurrentSlide() {
  //   if (this.state.slideIndex === (this.props.slider.length - 1) ) {
  //     this.setState({ slideIndex: 0 })
  //   } else {
  //     this.setState({ slideIndex: this.state.slideIndex + 1 })
  //   }
  // }

  render() {
    const slider = {}
    return (
      <div className="testimonial-slider-container">
        <Slider>
          {this.props.slider.map((testimonial, index) => (
            <TestimonialSlide testiText={testimonial.testiText} clientName={testimonial.clientName} caseType={testimonial.caseType} />
          ))}
        </Slider>
        {/* <div className="testimonial-slider">
          <div className="arrow-container" onClick={this.decrementCurrentSlide}>
            <IconContext.Provider value={{ size: "37px", className: "fa-arrow" }}>
              <FaAngleLeft/>
            </IconContext.Provider>
          </div>
          <div className="sliderContent">
              <div className="slide">
                <div className="stars">
                <IconContext.Provider value={{ size: "20px" }}>
                  <FaStar/><FaStar/><FaStar/><FaStar/><FaStar/>
                </IconContext.Provider>
                </div>
                <div className="review">
                  {this.props.slider[this.state.slideIndex].testiText}
                </div>
                <div className="name">
                  {this.props.slider[this.state.slideIndex].clientName}
                </div>
                <div className="type">
                  {this.props.slider[this.state.slideIndex].caseType}
                </div>
              </div>
          </div>
          <div className="arrow-container" onClick={this.incrementCurrentSlide}>
            <IconContext.Provider value={{ size: "37px", className: "fa-arrow" }}>
              <FaAngleRight/>
            </IconContext.Provider>
          </div>
        </div> */}
      </div>
    );
  }
}

TestimonialSlider.propTypes = {
  slider: PropTypes.array,
}

TestimonialSlider.defaultProps = {
  slider: []
}

export default TestimonialSlider
