import React from 'react'
import PropTypes from 'prop-types'
import Accordion from './Accordion'

const FaqAccordion = ({ data }) => (
  <div className="faq-accordion">
    <h5>{data.title}</h5>
    <h2>{data.subtitle}</h2>
    <Accordion rows={data.accordion} />
  </div>
)

FaqAccordion.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    accordion: PropTypes.array,
  }),
}

export default FaqAccordion
