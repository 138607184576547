import React from 'react'
import PropTypes from 'prop-types'
import { FaPlus, FaMinus } from 'react-icons/fa';

const Accordion = class extends React.Component {
  state = {
    openRow: 0
  }

  render() {
    const rows = []
    return (
      <div className="accordion">
        {this.props.rows.map((row, index) => (
          <div className="accordion-row">
            <div className="accordion-row-header" onClick={() => index === this.state.openRow ? this.setState({openRow: null}) : this.setState({openRow: index})}>
              {row.header}
              { index == this.state.openRow ? (
                <FaMinus/>
              ) : (
                <FaPlus/>
              )}
            </div>
            <div className={ index == this.state.openRow ? "accordion-row-body show" : "accordion-row-body hide"}>
              {row.body}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

Accordion.propTypes = {
  rows: PropTypes.array
}

Accordion.defaultProps = {
  rows: []
}

export default Accordion
