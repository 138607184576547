import React from 'react'
import PropTypes from 'prop-types'
import { IconContext } from "react-icons";
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { useSwipeable, Swipeable } from 'react-swipeable'

const Slider = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {activeSlide: 0};
    this.slidesBodyRef = React.createRef()
    this.slideRefs = []
  }

  decrementCurrentSlide() {
    let rightSlideIndex = this.state.activeSlide === this.props.children.length-1 ? 0 : this.state.activeSlide+1;
    let right = this.slideRefs[rightSlideIndex]
    right.style.display = "none";
    setTimeout(() => right.style.display = "flex", 350 );
    if (this.state.activeSlide === 0 ) {
      this.setState({ activeSlide: this.props.children.length - 1 })
    } else {
      this.setState({ activeSlide: this.state.activeSlide - 1 })
    }
  }

  incrementCurrentSlide() {
    let leftSlideIndex = this.state.activeSlide === 0 ? this.props.children.length-1 : this.state.activeSlide-1;
    let left = this.slideRefs[leftSlideIndex]
    left.style.display = "none";
    setTimeout(() => left.style.display = "flex", 550 );
    if (this.state.activeSlide === (this.props.children.length - 1) ) {
      this.setState({ activeSlide: 0 })
    } else {
      this.setState({ activeSlide: this.state.activeSlide + 1 })
    }
  }

  componentDidMount() {
    let maxHeight = 0;
    let maxWidth = 0;
    for (let slideRef of this.slideRefs) {
        maxHeight = slideRef.offsetHeight > maxHeight ? slideRef.offsetHeight : maxHeight;
        maxWidth = slideRef.offsetWidth > maxWidth ? slideRef.offsetWidth : maxWidth;
    }
    this.slideRefs.map((ref) => ref.style.position = "absolute")
    this.slidesBodyRef.current.style.height = maxHeight + "px";
    this.slidesBodyRef.current.style.width = maxWidth + "px";
    this.slidesBodyRef.current.style.display = "flex";
  }

  render() {
    return (
    <div className="slides-container">
        <div className="slides">
            <div className="slides-body">
                <div className="arrow-container" onClick={ () => this.decrementCurrentSlide()}>
                    <IconContext.Provider value={{ size: "37px", className: "fa-arrow" }}>
                        <FaAngleLeft/>
                    </IconContext.Provider>
                </div>
                    <div ref={this.slidesBodyRef} className="slide-container">
                        {this.props.children.map((child, index) => (
                            <div 
                                className={index === this.state.activeSlide ? "slide-content center" : index === this.state.activeSlide+1 || (this.state.activeSlide === this.props.children.length-1 && index === 0 ) ? "slide-content right" : "slide-content left" } 
                                ref={(ref) => { this.slideRefs && (this.slideRefs[index] = ref); return true; }}
                            >
                                <Swipeable onSwipedLeft={ () => this.incrementCurrentSlide()} onSwipedRight={ () => this.decrementCurrentSlide()}>
                                    <div>
                                        {child}
                                    </div>
                                </Swipeable>
                            </div>
                        ))}
                    </div>
                <div className="arrow-container" onClick={ () => this.incrementCurrentSlide()}>
                    <IconContext.Provider value={{ size: "37px", className: "fa-arrow" }}>
                        <FaAngleRight/>
                    </IconContext.Provider>
                </div>
            </div>
        </div>
    </div>
    )
  }
}

Slider.propTypes = {
    children: PropTypes.node.isRequired,
    titles: PropTypes.array
}

export default Slider
