import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { FaRegCheckCircle } from 'react-icons/fa';

const ContentPhoto = ({ data }) => (
  <div className="content-photo">
    <div className="content-info">
      <h5>{data.title}</h5>
      <h2>{data.subtitle}</h2>
      <p>{data.description}</p>
      <div className="checklist">
        {data.checklist.map((item, index) => (
          <div className="item">
            <FaRegCheckCircle/>
            {item.itemText}
          </div>
        ))}
      </div>
    </div>
    <Img fixed={data.photo.childImageSharp.fixed}  />
  </div>
)

ContentPhoto.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    photo: PropTypes.string,
    checklist: PropTypes.array,
  }),
}

export default ContentPhoto
