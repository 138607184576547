import React from 'react'
import PropTypes from 'prop-types'
import CountUp, {startAnimation} from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

const ContentStats = class extends React.Component {
  state = {
    didViewCountUp: false
  };

  onVisibilityChange = isVisible => {
    if (isVisible) {
      this.setState({didViewCountUp: true});
    }
  }

  render() {
    return (
      <div className="content-stats">
        <h5>{this.props.title}</h5>
        <h2>{this.props.subtitle}</h2>
        <div className="settlments">
          {this.props.settlementExamples.map((settlement, index) => (
            <div className="settlement">
              <div className="settlment-amount">
              <VisibilitySensor onChange={this.onVisibilityChange} offset={{
                top:
                  10
              }} delayedCall>
                <CountUp duration={2} delay={.5} separator="," end={this.state.didViewCountUp ? settlement.settlementDollarAmount : 0} prefix="$"/>
              </VisibilitySensor>
              </div>
              <div className="settlment-description">
                {settlement.settlementDescription}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

ContentStats.propTypes = {
  // data: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    settlementExamples: PropTypes.array,
  // }),
}

ContentStats.defaultProps = {
  // data: {
    title: "",
    subtitle: "",
    settlementExamples: [],
  // },
}

export default ContentStats
