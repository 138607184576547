import React from 'react'
import PropTypes from 'prop-types'
import { IconContext } from "react-icons";
import { FaStar } from 'react-icons/fa';

const TestimonialSlide = ({ testiText, clientName, caseType }) => (
  <div className="testimonial-slide">
        <div className="slide">
            <div className="stars">
                <IconContext.Provider value={{ size: "20px" }}>
                  <FaStar/><FaStar/><FaStar/><FaStar/><FaStar/>
                </IconContext.Provider>
            </div>
            <div className="review">
                {testiText}
            </div>
            <div className="name">
                {clientName}
            </div>
            <div className="type">
                {caseType}
            </div>
        </div>
  </div>
)

TestimonialSlide.propTypes = {
    testiText: PropTypes.string,
    clientName: PropTypes.string,
    caseType: PropTypes.string,
}

export default TestimonialSlide
