import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import ConsultationForm from './ConsultationForm'

const HeroForm = ({ data }) => (
  <div className="HeroForm">
    <div className="content">
      <div className="left-content">
        <h1>{data.heading}</h1>
        <p>
          {data.subheading}
        </p>
        <div className="as-featured-in">
          <h5>AS FEATURED IN</h5>
          <div className="logos-list">
          {data.asFeaturedIn.map((logo, index) => (
            <div className="logo-container">
              <Img fixed={logo.image.childImageSharp.fixed} imgStyle={{width: "unset", height: "unset"}} />
            </div>
          ))}
          </div>
        </div>
      </div>
      <div className="right-content">
        <ConsultationForm darkTheme={true} />
      </div>
    </div>
  </div>
)

HeroForm.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string,
    subheading: PropTypes.string,
    asFeaturedIn: PropTypes.array,
  }),
}

export default HeroForm
