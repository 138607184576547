import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { useState } from 'react';
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
// import MessageBanner from 
import HeroForm from '../components/HeroForm'
import ContentStats from '../components/ContentStats'
import ContentPhoto from '../components/ContentPhoto'
import ContentIcons from '../components/ContentIcons'
import ContentSteps from '../components/ContentSteps'
import TestimonialSlider from '../components/TestimonialSlider'
import FaqAccordion from '../components/FaqAccordion'
import CallToAction from '../components/CallToAction'
import Navbar from '../components/Navbar'
import Slider from '../components/Slider'
import TestimonialSlide from '../components/TestimonialSlide'
import Curve from '../images/law-curve.svg'
import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

export const IndexPageTemplate = ({
  hero1Form,
  contentStats1,
  contentPhoto1,
  contentIcons1,
  testi1Slider,
  faq1Accordion,
  contentSteps1,
  cta1basic,
}) => {
  const [triggerCtaPopUp, setTriggerCtaPopUp] = useState(false);
  
  return (
    <div className="site-content">
      <div className="car-crash-background">
        <Navbar ctaCallback={ () => setTriggerCtaPopUp(true) }/>
        <HeroForm data={hero1Form}/>
        <Element name="scroll-results">
          <Curve className="curve"/>
        </Element>
      </div>
      <ContentStats title={contentStats1.title} subtitle={contentStats1.subtitle} settlementExamples={contentStats1.settlementExamples}/>
      <Element name="scroll-cases">
        <ContentPhoto data={contentPhoto1}/>
      </Element>
      <ContentIcons data={contentIcons1}/>
      <TestimonialSlider slider={testi1Slider.slider}/>
      <Element name="scroll-faq">
        <FaqAccordion data={faq1Accordion}/>
      </Element>
      <Element name="scroll-how-it-works">
        <ContentSteps data={contentSteps1}/>
      </Element>
      <Element name="scroll-contact">
        <CallToAction data={cta1basic} forceShowPopUp={triggerCtaPopUp} forceClosePopUp={() => setTriggerCtaPopUp(false)}/>
      </Element>
    </div>
  )
}


IndexPageTemplate.propTypes = {
  hero1Form: PropTypes.object,
  contentStats1: PropTypes.object,
  contentPhoto1: PropTypes.object,
  contentIcons1: PropTypes.object,
  testi1Slider: PropTypes.object,
  faq1Accordion: PropTypes.object,
  contentSteps1: PropTypes.object,
  cta1basic: PropTypes.object,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        hero1Form={frontmatter.hero1Form}
        contentStats1={frontmatter.contentStats1}
        contentPhoto1={frontmatter.contentPhoto1}
        contentIcons1={frontmatter.contentIcons1}
        testi1Slider={frontmatter.testi1Slider}
        faq1Accordion={frontmatter.faq1Accordion}
        contentSteps1={frontmatter.contentSteps1}
        cta1basic={frontmatter.cta1basic}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        hero1Form {
          heading
          subheading
          asFeaturedIn {
            image {
              childImageSharp {
                fixed(width: 180) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
        }
        contentStats1 {
          title
          subtitle
          settlementExamples {
            settlementDollarAmount
            settlementDescription
          }
        }
        contentPhoto1 {
          title
          subtitle
          description
          photo {
            childImageSharp {
              fixed(width: 700) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          checklist {
            itemText
          }
        }
        contentIcons1 {
          title
          iconList {
            icon
            itemText
          }
        }
        testi1Slider {
          slider {
            testiText
            clientName
            caseType
          }
        }
        faq1Accordion {
          title
          subtitle
          accordion {
            header
            body
          }
        }
        contentSteps1 {
          title
          subtitle
          contentSteps {
            stepIcon {
              childImageSharp {
                fluid(maxWidth: 200, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            stepTitle
            stepDescription
          }
        }
        cta1basic {
          largeTitle
          subtitleBold
          subtitleLight
          buttonText
        }
      }
    }
  }
`
